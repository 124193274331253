<template>
  <div>
    <div class="add_box form">
      <el-form
        ref="batchForm"
        :model="batchForm"
        :rules="ifrules ? ruleBatch : {}"
        class="demo-ruleForm"
        label-width="250px"
        :disabled="!ifrules"
      >
        <h2>Enterprise Info</h2>
        <el-form-item label="Contract Type" prop="contractType">
          <el-select
            v-model.trim="batchForm.contractType"
            placeholder="Please Choose"
            :disabled="isDisable"
          >
            <el-option
              v-for="item in UserFlagList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="Sales ERP" prop="saler">
          <el-input
            v-model.trim="batchForm.saler"
            type=""
            placeholder="Please enter the Sales ERP"
            maxlength="100"
            :disabled="isDisable"
          />
        </el-form-item>
        <el-form-item label="Enterprise Name" prop="enterpriseName">
          <el-input
            v-model.trim="batchForm.enterpriseName"
            placeholder="Please enter the Enterprise Name"
            maxlength="50"
            disabled
          />
          <el-button
            v-if="!$route.query.id"
            icon="el-icon-search"
            style="margin-left:40px"
            @click="clibtn"
          >
            Search
          </el-button>
        </el-form-item>
        <h2>Contract Info</h2>
        <el-form-item v-if="$route.query.id" label="Contract No" prop="contractNo">
          <el-input
            v-model.trim="batchForm.contractNo"
            type
            placeholder="Please enter the Contract No"
            maxlength="50"
            disabled
          />
        </el-form-item>
        <el-form-item label="OU" prop="ouInfo">
          <el-select
            v-model="batchForm.ouInfo"
            placeholder="Please Choose the OU"
            :disabled="isDisableOU"
          >
            <el-option
              v-for="item in (ifTH ? OUlistTh : OUlist)"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="Contract Begin Time" prop="contractStartTime">
          <el-date-picker
            v-model.trim="batchForm.contractStartTime"
            type="date"
            placeholder="Please choose Contract Begin Time"
            value-format="yyyy-MM-dd"
            format="dd-MM-yyyy"
            :disabled="isDisable"
          />
        </el-form-item>
        <el-form-item label="Contract End Time" prop="contractEndTime">
          <el-date-picker
            v-model.trim="batchForm.contractEndTime"
            type="date"
            placeholder="Please choose Contract End Time"
            value-format="yyyy-MM-dd"
            format="dd-MM-yyyy"
            :picker-options="pickerOptions"
            :disabled="isDisable"
          />
        </el-form-item>
        <el-form-item label="Enterprise Number" prop="enterpriseId">
          <el-input
            v-model.trim="batchForm.enterpriseId"
            type
            placeholder="Please enter the Enterprise Number"
            maxlength="50"
            disabled
          />
        </el-form-item>
        <el-form-item label="Support Business Credit" prop="isEnterpriseCredit">
          <el-radio-group v-model.trim="batchForm.isEnterpriseCredit" :disabled="isDisable">
            <el-radio :label="1">Yes</el-radio>
            <el-radio :label="0">No</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="Support COD(Cash On Delivery)" prop="isCod">
          <el-radio-group v-model.trim="batchForm.isCod" :disabled="isDisable">
            <el-radio :label="1">Yes</el-radio>
            <el-radio :label="0">No</el-radio>
          </el-radio-group>
        </el-form-item>
        <!--发票信息-->
        <el-form-item label="Invoice" prop="isInvoice">
          <el-radio-group v-model.trim="batchForm.isInvoice" :disabled="isDisable">
            <el-radio :label="1">Yes</el-radio>
            <el-radio :label="0">No</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="!ifTH" label="Invoice Type" prop="invoiceType">
          <el-radio-group v-model.trim="batchForm.invoiceType" :disabled="isDisable">
            <el-radio :label="1">Common Invoice</el-radio>
            <el-radio :label="2">VAT Invoice</el-radio>
            <el-radio :label="3">Electronic Invoice</el-radio>
            <el-radio :label="4">Electronic VAT</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-else label="Invoice Type" prop="invoiceType">
          <el-radio-group v-model.trim="batchForm.invoiceType" :disabled="isDisable">
            <el-radio :label="3">Electronic Invoice</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="Price Mode" prop="priceType">
          <el-radio-group v-model.trim="batchForm.priceType" :disabled="isDisable">
            <el-radio :label="0">Promotion Price</el-radio>
            <el-radio :label="1">Agreement Price</el-radio>
            <el-radio :label="2">Lower Price Priority</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="!ifTH" label="Ware Pool Type" prop="poolType">
          <el-radio-group v-model.trim="batchForm.poolType" :disabled="isDisable">
            <el-radio :label="0">Standard Ware-pool </el-radio>
            <el-radio :label="1">Exclusive Ware-pool </el-radio>
            <el-radio :label="2">Standard+Exclusive Ware-pool </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="Take Days" prop="takeDays">
          <el-input
            v-model.trim="batchForm.takeDays"
            type
            placeholder="Please enter the Take Days"
            maxlength="10"
            :disabled="isDisable"
          />
        </el-form-item>
        <div style="height:10px" />
        <div v-if="ifrules" class="chack_box">
          <div class="children_box">
            <el-form-item
              v-if="isShowValid"
              label="Validity Type"
              prop="state"
            >
              <el-radio-group v-model.trim="batchForm.state">
                <el-radio :label="0">Valid</el-radio>
                <el-radio :label="1">Invalid</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="" prop="state">
              <el-radio-group v-model.trim="batchForm.state" />
            </el-form-item>
            <el-button
              v-if="dialogStatusB == 'create'"
              type="primary"
              size="mini"
              @click="createDataB('batchForm')"
            >
              Submit
            </el-button>
            <el-button
              v-else-if="dialogStatusB == 'update'"
              
              type="primary"
              size="mini"
              @click="updateDataB('batchForm')"
            >
              Submit
            </el-button>
          </div>
        </div>
      </el-form>
      <el-form
        v-show="!ifrules"
        ref="postForm"
        :model="postForm"
        :rules="rules"
        class="demo-ruleForm"
        label-width="250px"
        style="margin-top:25px"
      >
        <h2 v-if="fleText != 'look'">Enterprise Info</h2>
        <el-form-item
          v-if="fleText != 'look'"
          label="Audit Result"
          prop="auditStatus"
        >
          <el-radio-group v-model.trim="postForm.auditStatus">
            <el-radio :label="1">Passed </el-radio>
            <el-radio :label="2">Rejected</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          v-if="fleText != 'look'"
          label="Audit Opinion"
          prop="auditContent"
        >
          <el-input
            v-model.trim="postForm.auditContent"
            type="textarea"
            placeholder="Please enter the Audit Opinion"
            :rows="5"
            maxlength="100"
          />
        </el-form-item>
      </el-form>
      <div
        v-if="!ifrules"
        style="width:100%;display: flex; justify-content: center;"
      >
        <el-button
          v-if="fleText != 'look'"
          type="primary"
          size="mini"
          @click="passBtn('batchForm')"
        >
          Submit
        </el-button>
        <el-button size="mini" @click="$router.back()">
          back
        </el-button>
      </div>
    </div>
    <el-dialog
      title="Companies list"
      :visible.sync="dialogFormVisible"
      width="900px"
    >
      <companies-list @seletBtn="seletBtn" />
    </el-dialog>
  </div>
</template>
<script>
import {
  getcontorDetails,
  creatContract,
  audit,
  putContor
} from '@/api/user/list';
import dateFormate from '../../utils/date'
import CompaniesList from '@/components/enterpriseComponents/CompaniesList.vue'

const defaultForm = {
  auditStatus: 0,
  auditContent: ''
};
const batchForm = {
  ouInfo: '',
  contractStartTime: '',
  contractEndTime: '',
  enterpriseId: '',
  isEnterpriseCredit: null,
  isCod: null,
  isInvoice:null,
  invoiceType: null,
  priceType: null,
  enterpriseName: '',
  poolType: null,
  contractType: 0,
  state: null,
  takeDays: '',
  saler: ''
};
export default {
  // 引入局部刷新
  name: 'Add',
  inject: ['reload'],
  components: {
    CompaniesList
  },
  data() {
    return {
      ifTH:false,
      emptyImgSrc: '',
      // 获取URL信息
      outeObject: this.$route.query,
      // 单个创建时初始数据
      batchForm: Object.assign({}, batchForm),
      postForm: Object.assign({}, defaultForm),
      dialogStatus: 'update',
      dialogStatusB: 'update',
      pickerOptions: {
        disabledDate: time => {
          return (
            time.getTime() <
            new Date(this.batchForm.contractStartTime).getTime() -
              0 * 24 * 60 * 60 * 1000
          ); //减去一天的时间代表可以选择同一天;
        }
      },
      downloadURL: '/system/user/downloadTarget',
      total: 0,
      // 批量创建user校验呢容
      ruleBatch: {
        contractType: [
          {
            required: true,
            message: 'Please enter the Contract Type',
            trigger: 'blur'
          }
        ],
        // poolType: [
        //   { required: true, message: 'Please choose', trigger: 'change' }
        // ],
        saler: [
          {
            required: true,
            message: 'Please enter the Sales ERP',
            trigger: 'blur'
          }
        ],
        enterpriseId: [
          {
            required: true,
            message: 'Please enter the Enterprise ID',
            trigger: 'blur'
          }
        ],
        ouInfo: [
          {
            required: true,
            message: 'Please enter the OU',
            trigger: 'change'
          }
        ],
        contractStartTime: [
          {
            required: true,
            message: 'Please enter the contract Begin Time',
            trigger: 'blur'
          }
        ],
        contractEndTime: [
          {
            required: true,
            message: 'Please enter the contract End Time',
            trigger: 'blur'
          }
        ],
        isEnterpriseCredit: [
          { required: true, message: 'Please choose', trigger: 'change' }
        ],
        invoiceType: [
          {
            required: true,
            message: 'Choose at least one',
            trigger: 'blur'
          }
        ],
        isCod: [
          { required: true, message: 'Please choose', trigger: 'change' }
        ],
        isInvoice:[
          {required: true, message: 'Please choose', trigger: 'change'}
        ],
        priceType: [
          { required: true, message: 'Please choose', trigger: 'change' }
        ],
        takeDays: [
          {
            required: true,
            message: 'Please enter the Take Days',
            trigger: 'blur'
          }
        ]
      },
      rules: {
        auditStatus: [
          {
            required: true,
            message: 'Please enter the audit Result',
            trigger: 'blur'
          }
        ]
      },
      UserFlagList: [{ value: 0, label: 'VSP' }],
      OUlist: [
        { value: '', label: 'Please choose' },
        { value: '0', label: 'PT.Retail Bersama Nasional' },
        { value: '1', label: 'PT Distributor Bersama Nusantara' },
      ],
      OUlistTh: [
        { value: '', label: 'Please choose' },
        { value: '0', label: 'Central Commerce Co.,Ltd' },
      ],
      // 0：未执行；1：执行中；2：执行失败；3：执行成功
      zhi: [
        { value: 0, label: 'Failed.' },
        { value: 1, label: 'The execution of' },
        { value: 2, label: 'On failure' },
        { value: 3, label: 'Execute successfully' }
      ],
      textMap: {
        update: 'update',
        create: 'create'
      },
      contractNo_: '',
      dialogFormVisible: false,
      ifrules: true,
      fleText: 'add',
      isDisableOU: false
    };
  },
  computed:{
    isDisable(){
      return this.batchForm.creditAuditState==1 && this.batchForm.businessAuditState==1
    },
    isShowValid(){
      let isShow=false
      if(this.dialogStatusB=='update'){
        if(this.batchForm.creditAuditState==1 && this.batchForm.businessAuditState==1){
          isShow=true
        }else{
          isShow=false
        }
        
      }else{
        isShow=false
      }
      return isShow
    }
  },
  watch: {
    '$route' :function (val){
      this.reload()
    },
    'batchForm.state' :function(val){
      if((this.batchForm.creditAuditState!=1 || this.batchForm.businessAuditState!=1) && this.batchForm.state==0 ){
        this.$message({message:'The contract  is invalid and cannot be audited'})
        this.batchForm.state=0
      }
    }
  },
  created() {
    if (process.env.VUE_APP_BASE_API == '/apiid') {
      this.ifTH = false;
    } else if (process.env.VUE_APP_BASE_API == '/apith') {
      this.ifTH = true;
    }
    if (Object.keys(this.outeObject).length == 0 || this.outeObject.flang === 'enterprise') { // 新建
      this.isDisableOU = false;
    } else { // 编辑
      this.isDisableOU = true;
    }
    if (!this.outeObject.id || this.outeObject.id == undefined) {
      this.dialogStatus = 'create';
      this.dialogStatusB = 'create';
      this.batchForm = Object.assign({}, batchForm);
      this.ifrules = true;
    } else {
      this.batchForm = Object.assign({}, batchForm);
      this.dialogStatus = 'update';
      this.dialogStatusB = 'update';
      if (this.outeObject.flang == 3 || this.outeObject.flang == 4) {
        this.ifrules = true;
      } else {
        this.ifrules = false;
      }
      if (this.outeObject.flang == 4) {
        this.batchForm.enterpriseName = this.outeObject.enterpriseName;
        this.batchForm.enterpriseId = this.outeObject.id;
      }
      if (
        this.outeObject.flang == 3 ||
        this.outeObject.flang == 2 ||
        this.outeObject.flang == 1 ||
        this.outeObject.flang == 4
      ) {
        this.fleText = 'add';
      }
      if (
        this.outeObject.flang == 3 ||
        this.outeObject.flang == 2 ||
        this.outeObject.flang == 1
      ) {
        getcontorDetails(this.outeObject).then(res => {
          this.batchForm = res.data;
          this.batchForm.enterpriseName = this.outeObject.enterpriseName;
          this.batchForm.contractStartTime=dateFormate(res.data.contractStartTime,'yyyy-MM-dd')
          this.batchForm.contractEndTime=dateFormate(res.data.contractEndTime,'yyyy-MM-dd')
          let nameState = 0;
          if (res.data.businessAuditState == 1) {  // 回显通过
            nameState = 1;
          } else if (res.data.businessAuditState == 2) {  // 回显拒绝
            nameState = 2;
          } else { // 待审核
            nameState = 0;
          }
          this.postForm.auditStatus = nameState;
          this.postForm.auditContent = res.data.modified || '';
        });
      }
      if (this.outeObject.flang == 'look') {
        this.fleText = 'look';
        this.batchForm.enterpriseName = this.outeObject.enterpriseName;
        this.batchForm.enterpriseId = this.outeObject.id;
        getcontorDetails(this.outeObject).then(res => {
          this.batchForm = res.data;
          this.batchForm.contractStartTime=dateFormate(res.data.contractStartTime,'yyyy-MM-dd')
          this.batchForm.contractEndTime=dateFormate(res.data.contractEndTime,'yyyy-MM-dd')
        });
      }
      if (this.outeObject.flang == 'quer') {
        this.ifrules = true;
        this.batchForm.enterpriseName = this.outeObject.enterpriseName;
        this.batchForm.enterpriseId = this.outeObject.id;
        this.dialogStatusB = 'create';
        // getcontorDetails(this.outeObject).then(res => {
        //   this.batchForm = res.data;
        //   this.batchForm.contractStartTime=dateFormate(res.data.contractStartTime,'yyyy-MM-dd')
        //   this.batchForm.contractEndTime=dateFormate(res.data.contractEndTime,'yyyy-MM-dd')
        // });
      }
      if (this.outeObject.flang == 'enterprise') {
        this.batchForm = Object.assign({}, batchForm);
        this.ifrules = true;
        this.batchForm.enterpriseName = this.outeObject.enterpriseName;
        this.batchForm.enterpriseId = this.outeObject.id;
        this.dialogStatusB = 'create';
      }
    }
    this.emptyImgSrc = '';
  },
  methods: {
    // 时间开始选择器
    startTimeStatus(value) {
      this.batchForm.contractStartTime = value;
    },
    // 时间结束选择器
    endTimeStatus(value) {
      this.batchForm.contractEndTime = value;
    },
    seletBtn(row) {
      this.dialogFormVisible = false;
      this.batchForm.enterpriseName = row.enterpriseName;
      this.batchForm.enterpriseId = row.id;
    },
    // 批量创建-新增确定
    createDataB() {
      this.$refs['batchForm'].validate(valid => {
        if (valid) {
          if (
            Date.parse(this.batchForm.contractStartTime) <
              Date.parse(this.batchForm.contractEndTime) ||
            Date.parse(this.batchForm.contractStartTime) ==
              Date.parse(this.batchForm.contractEndTime)
          ) {
            delete this.batchForm.enterpriseName;
            creatContract(this.batchForm).then(res => {
              if (res.code === 200) {
                setTimeout(() => {
                  this.$message({
                    message: 'successfully upload',
                    type: 'success'
                  });
                  this.$router.push({
                    name: 'contract list',
                    path: '/contractManage/contractList'
                  });
                }, 1000);
              } else {
                setTimeout(() => {
                  this.$message({
                    message: res.msg,
                    type: 'error'
                  });
                }, 1000);
              }
            });
          } else {
            this.$message({
              message: 'The end time cannot be shorter than the start time',
              type: 'error'
            });
          }
        } else {
          //console.log('error submit!!');
          return false;
        }
      });
    },
    updateDataB() {
      this.$refs['batchForm'].validate(valid => {
        if (valid) {
          if (
            Date.parse(this.batchForm.contractStartTime) <
              Date.parse(this.batchForm.contractEndTime) ||
            Date.parse(this.batchForm.contractStartTime) ==
              Date.parse(this.batchForm.contractEndTime)
          ) {
            var obj = this.batchForm;
            delete obj.enterpriseName;
            delete obj.createPaper;
            delete obj.createTime;
            delete obj.modified;
            delete obj.modifier;
            delete obj.remark;
            delete obj.updatePaper;
            delete obj.updateTime;
            delete obj.invoiceMode;
            delete obj.inForce;
            delete obj.created
            delete obj.creator
            delete obj.modified
            delete obj.modifier
            // if (this.outeObject.flang == 1) {
            //   obj.editState = 3;
            // } else if (this.outeObject.flang == 2) {
            //   obj.editState = 1;
            // } else {
            //   obj.editState = 2;
            // }
            // obj.editState = 0;

            putContor(obj).then(res => {
              if (res.code == 200) {
                setTimeout(() => {
                  this.$message({
                    message: 'successfully upload',
                    type: 'success'
                  });
                  this.$router.push({
                    name: 'contract list',
                    path: '/contractManage/contractList'
                  });
                }, 1000);
              } else {
                setTimeout(() => {
                  this.$message({
                    message: res.msg,
                    type: 'error'
                  });
                }, 1000);
              }
            });
          } else {
            this.$message({
              message: 'The end time cannot be shorter than the start time',
              type: 'error'
            });
          }
        } else {
          return false;
        }
      });
    },
    clibtn() {
      this.dialogFormVisible = true;
    },
    passBtn() {
      if(this.postForm.auditStatus==2 && !this.postForm.auditContent){
        return this.$message({message:'Please fill in the reason for rejection'})
      }
      this.$refs['postForm'].validate(valid => {
        if (valid) {
          var obj = {};
          if (this.outeObject.flang == 1) {
            obj = {
              auditType: 4,
              contractId: Number(this.outeObject.id)
            };
          } else {
            obj = {
              auditType: 5,
              contractId:  Number(this.outeObject.id)
            };
          }
          audit(Object.assign(obj, this.postForm)).then(res => {
            if(res.code==200){
              this.$message({
                message: 'operate successfully',
                type: 'success'
              });
              if (this.outeObject.flang == 1) {
                this.$router.push({
                  name: 'contract review',
                  path: '/contractManage/contractAudit'
                });
              } else {
                this.$router.push({
                  name: 'Review contract financial information',
                  path: '/contractManage/contractCreditAudit'
                });
              }
            }else{
              this.$message({
                message: res.msg,
                type: 'error'
              });
            }
          });
        } else {
          //console.log('error submit!!');
          return false;
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.add_box {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 20px 10px;
  box-sizing: border-box;
  background: #fff;
  h2 {
    height: 20px;
    font-size: 20px;
    font-family: Arial;
    font-weight: normal;
    color: rgba(36, 37, 38, 1);
  }
}
.logo_no {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.form {
  ::v-deep .el-input {
    width: 300px;
    //margin-right: 30px;
  }
  ::v-deep .el-input__inner {
    height: 32px;
    line-height: 30px;
  }
  ::v-deep .el-pagination__editor.el-input {
    width: 50px;
  }
}
.btn_box {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}
/deep/.el-button {
  // padding: 0 12px !important;
}
.serch_box {
  width: 100%;
  border-radius: 5px;
  background: #fff;
  box-sizing: border-box;
  ::v-deep .el-input__inner {
    height: 32px;
    line-height: 30px;
  }
}
.listbtn_loding {
  width: 100%;
  display: flex;
  align-items: center;
}
::v-deep .el-dialog__body {
  padding-bottom: 50px;
}
/deep/.el-button {
  // padding: 0 12px !important;
  //width: 100px;
  font-family: ArialMT;
  font-size: 14px;
}

::v-deep .el-button--primary {
  color: #fff;
  background-color: #2262ff;
  border-color: #2262ff;
}
::v-deep .el-button--text {
  color: #2262ff;
}

::v-deep .el-radio-group {
  .el-radio__label {
    color: #242526;
  }
  .is-checked {
    .el-radio__inner {
      background-color: #2262ff;
    }
    .el-radio__label {
      color: #242526;
    }
  }
}
::v-deep .el-table {
  .cell {
    white-space: pre-wrap;
    word-break: keep-all;
  }
}

::v-deep .el-pager {
  li {
    border: 0;
  }
}
.chack_box {
  position: fixed;
  bottom: 5px;
  left: 0;
  width: 86.8%;
  box-shadow: 2px 2px 5px #888888;
  background: #fff;
  padding-top: 22px;
  margin-left: 255px;
}
.children_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    margin-right: 200px;
    margin-top: -22px;
  }
}
</style>
